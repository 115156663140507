.special-card {
  width: 253px;
  height: 385px;
  position: relative;
  margin: 20px 10px 0 10px;
  background-image: url("../../images/BG.png");
  background-repeat: no-repeat;
  background-size: cover;

  .special-card-owned {
    border-radius: 50%;
    background-color: white;
    color: #ed9b28;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    width: 40px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }

  .special-card-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .special-card-image {
      height: 159px;
      width: 217px;
      margin: 18px auto 0 auto;
    }

    .special-card-info {
      margin: 16px auto 0 20px;
      .special-card-title {
        //height: 40px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: white;
        margin-bottom: 12px;
      }

      .special-card-description {
        //height: 65px;
        background: rgba(250, 250, 250, 0.5);
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        color: #000000;
        padding: 8px;
        max-width: 213px;
        //overflow: auto;
      }
    }

    .special-card-footer {
      height: 33px;
      width: 225px;
      background: rgba(237, 155, 40, 0.12);
      margin: 14px;
      margin-top: auto;
      display: inline-flex;

      .special-card-cost,
      .special-card-cost2 {
        font-weight: 700;
        font-size: 12px;
        line-height: 13px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;

        .icon {
          margin-right: 3px;
        }
      }

      .special-card-cost2 {
        border-left: 1px solid rgba(255, 255, 255, 0.4);
      }

      /*       .special-card-buy {
        background: #ed9b28;
        border-radius: 0 0 14px 0;
        border: 0;
        width: 140px;
        color: white;
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      } */
    }
  }
}
