.specials-menu-wrapper {
  display: flex;
  max-height: 70vh;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  transition: 250ms ease-in-out;
  right: 100%;

  &.open {
    transform: translate(100%, -50%);
  }

  .specials-menu-container {
    background: rgba(255, 255, 255, 1);
    border: 4px solid #ed9b28;
    border-left-width: 0;
    padding: 40px;
    max-height: 100%;
    min-height: 484px;
    overflow: auto;
    position: relative;

    .specials-menu-list {
      .flex {
        display: flex;
        flex-direction: column;
      }

      .btns-container {
        align-self: center;
        .buy-btn,
        .use-btn {
          font-size: 16px;
          color: white;
          font-family: loos-normal, sans-serif;
          font-weight: 800;
        }

        .buy-btn {
          padding: 8px 46.75px;
          background: #7054fb;
          border-radius: 0px 0px 0px 20px;
        }

        .use-btn {
          padding: 8px 46.75px;
          margin-left: 2px;
          background: #ed9b28;
          border-radius: 0px 0px 20px 0px;
        }
      }
    }
  }

  .specials-menu-toggle {
    font-weight: 700;
    font-size: 25px;
    line-height: 31px;
    transform: rotate(90deg) translate(-18%, 90.2px);
    position: absolute;
    left: 100%;
    top: 50%;
    background: rgba(255, 255, 255, 1);
    border: 4px solid #ed9b28;
    border-bottom: 0;
    padding: 16px 40px;
    display: flex;
    align-items: center;
    transition: 250ms ease-in-out;
    cursor: pointer;

    .arrow-up {
      width: 0;
      height: 0;
      margin-left: 12px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid black;
    }

    .arrow-down {
      width: 0;
      height: 0;
      margin-left: 12px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid black;
    }
  }
}
